import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Route } from 'react-router-dom'
import localForage from 'localforage'
import Modal from 'react-modal'
import submit from '../service/reportsubmit'
import toast from '../utils/toast'
import LoadBlock from '../components/LoadBlock'
import './ReportsSubmit.scss'
import klickicon from '../img/klickicon.png'
import euplate from '../img/plate.png'
import { callApiChecked } from '../middleware/api'
import { isGuestMode } from '../index'
import { withRouter } from 'react-router'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const customStylesModal = {
  overlay: {
    zIndex: '40'
  }
}

const uuidv1 = require('uuid/v1')

const mapStateToProps = state => {
  return {
    report: state.currentReport,
    connection: state.connection,
    stepsConfig: state.config.steps,
    user: state.user
  }
}

const b64toBlob = (b64Data, sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays)
}
const blackGuid = '00000000-0000-0000-0000-000000000000'
const blackFile = new File(
  [
    b64toBlob(
      'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=='
    )
  ],
  'black.png',
  { type: 'image/png' }
)
localForage.setItem('image_' + blackGuid, blackFile)

// Delete Button is necessary for multi images

const specialDefaultComments = {
  30: 'Versicherer: \nVertragsnummer: ',
  36: 'Name: \nKennzeichen: ',
  22: 'Fahrzeughalter Tel. (Festnetz/Mobil):\n\nE-Mail: '
}

class ReportStep extends Component {
  constructor(props) {
    super(props)
    this.state = { imageMap: {}, modalIsOpen: false }
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  openModal(image) {
    this.setState({ modalIsOpen: image })
  }

  fileSelected(e, step) {
    let filesToBeAdded = []

    const fileInput = e.target
    const fileList = fileInput.files

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        filesToBeAdded.push(fileList[i])
      }
    }

    if (filesToBeAdded.length) {
      let newSteps = Object.assign({}, this.props.report.steps)
      if (!step.multiple || !(step.id in newSteps)) {
        newSteps[step.id] = []
      } else {
        newSteps[step.id] = [...newSteps[step.id]]
      }

      let savePromises = []
      filesToBeAdded.forEach(file => {
        let uuid = uuidv1()
        savePromises.push(
          localForage.setItem('image_' + uuid, file).then(() => {
            newSteps[step.id].push({ imageId: uuid })
            if (
              step.special === 'insurance' &&
              step.id in this.props.report.steps &&
              this.props.report.steps[step.id].length
            ) {
              newSteps[step.id][0].comment = this.props.report.steps[
                step.id
              ][0].comment
            }
          })
        )
      })

      Promise.all(savePromises).then(() => {
        fileInput.value = ''

        this.props.dispatch({
          type: 'SET_REPORT',
          report: Object.assign({}, this.props.report, {
            steps: newSteps,
            isSyncWithServer: false
          })
        })
        if (step.special === 'contact') {
          this.openModal(newSteps[step.id].length - 1)
        }
      })
    }
  }

  componentWillUnmount() {
    Object.values(this.state.imageMap).forEach(URL.revokeObjectURL)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.report !== this.props.report) {
      return true
    }
    if (nextProps.match.params.step !== this.props.match.params.step) {
      return true
    }
    if (nextState.imageMap !== this.state.imageMap) {
      return true
    }
    if (nextState.modalIsOpen !== this.state.modalIsOpen) {
      return true
    }
    return false
  }

  imageDisplayProps(stepImage) {
    if (stepImage.imageId && stepImage.imageId in this.state.imageMap) {
      return {
        imgKey: stepImage.imageId,
        imgSrc: this.state.imageMap[stepImage.imageId]
      }
    }
    if (stepImage.security_hash) {
      return {
        imgKey: stepImage.dbId,
        imgSrc: `https://kv-api.rucker-sv.de/p2rimg/${stepImage.dbId}?signature=${stepImage.security_hash}`
      }
    }

    return {}
  }

  render() {
    const { stepsConfig } = this.props
    const step = stepsConfig.find(
      step => step.id === Number(this.props.match.params.step)
    )
    let stepIdx = stepsConfig.indexOf(step)
    let nextStep
    let nextStepOffset = 1
    do {
      nextStep =
        stepIdx < stepsConfig.length - nextStepOffset
          ? stepsConfig[stepIdx + nextStepOffset]
          : null
      nextStepOffset++
    } while (nextStep !== null && nextStep.deleted)

    const currentStepData = this.props.report.steps[step.id]

    if (currentStepData) {
      let imagePromises = []
      let imageIds = []
      currentStepData.forEach(stepImage => {
        if (stepImage.imageId && !(stepImage.imageId in this.state.imageMap)) {
          imagePromises.push(localForage.getItem('image_' + stepImage.imageId))
          imageIds.push(stepImage.imageId)
        }
      })
      if (imagePromises.length) {
        Promise.all(imagePromises).then(results => {
          let imageMap = Object.assign({}, this.state.imageMap)
          results.forEach((file, i) => {
            if (!file) {
              console.error('Image not found in device storage', imageIds[i])
            }
            imageMap[imageIds[i]] = file ? URL.createObjectURL(file) : null
          })
          this.setState({ imageMap })
        })
      }
    }

    let styles = {
      width: '100%',
      display: 'flex',
      marginTop: '5px',
      marginBottom: '5px',
      backgroundColor: 'white',
      alignItems: 'center',
      justifyContent: 'center'
    }
    if (step.multiple) {
      styles.minHeight = '15vh'
    } else {
      styles.height = '70vh'
    }
    if (step.special === 'insurance') {
      styles.height = '40vh'
    }

    const currentStepOpenImageModelData = currentStepData
      ? currentStepData[this.state.modalIsOpen]
      : undefined // this.state.modalIsOpen holds the array index of currently open step
    const {
      imgKey: currentStepOpenImageModalImgKey,
      imgSrc: currentStepOpenImageModalImgSrc
    } = currentStepOpenImageModelData
      ? this.imageDisplayProps(currentStepOpenImageModelData)
      : {}

    return (
      <div>
        <Link to="/reports/submit">
          <small>Zur Übersicht</small>
        </Link>
        <br />
        <strong className="subtitle">{step.name}</strong>

        {this.specialStep(step)}

        {!(currentStepData && currentStepData.length) && step.infoimg ? (
          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            Beispiel:
            <br />
            <img
              style={{ width: '60%', opacity: 0.8 }}
              src={step.infoimg}
            ></img>
          </div>
        ) : null}

        <div style={styles}>
          {step.multiple ? (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                paddingBottom: '8px'
              }}
            >
              <div style={{ flex: '50%', padding: '0 4px' }}>
                {currentStepData && currentStepData.length
                  ? currentStepData
                      .slice(0, Math.ceil(currentStepData.length / 2))
                      .map((stepImage, i) => {
                        let { imgKey, imgSrc } = this.imageDisplayProps(
                          stepImage
                        )
                        if (!imgSrc) {
                          return null
                        }
                        return (
                          <span
                            className="overleft"
                            key={imgKey}
                            onClick={() => this.openModal(i)}
                          >
                            <img
                              style={{
                                verticalAlign: 'middle',
                                marginTop: '8px',
                                display: 'block',
                                padding: '0px',
                                border: '2px solid #669fe4'
                              }}
                              alt={imgKey}
                              src={imgSrc}
                            />
                            <img className="after" src={klickicon} alt="" />
                          </span>
                        )
                      })
                  : null}
              </div>

              <div style={{ flex: '50%', padding: '0 4px' }}>
                {currentStepData && currentStepData.length
                  ? currentStepData
                      .slice(Math.ceil(currentStepData.length / 2))
                      .map((stepImage, i) => {
                        let { imgKey, imgSrc } = this.imageDisplayProps(
                          stepImage
                        )
                        if (!imgSrc) {
                          return null
                        }
                        return (
                          <span
                            className="over"
                            key={imgKey}
                            onClick={() =>
                              this.openModal(
                                i + Math.ceil(currentStepData.length / 2)
                              )
                            }
                          >
                            <img
                              style={{
                                verticalAlign: 'middle',
                                marginTop: '8px',
                                display: 'block',
                                padding: '0px',
                                marginLeft: '10px',
                                border: '2px solid #669fe4'
                              }}
                              alt={imgKey}
                              src={imgSrc}
                            />
                            <img className="after" src={klickicon} alt="" />
                          </span>
                        )
                      })
                  : null}
              </div>
            </div>
          ) : currentStepData && currentStepData.length ? (
            currentStepData.map((stepImage, i) => {
              let { imgKey, imgSrc } = this.imageDisplayProps(stepImage)
              if (!imgSrc) {
                return null
              }
              return (
                <span
                  className="oversmall"
                  key={imgKey}
                  onClick={() => this.openModal(i)}
                >
                  <img
                    alt={imgKey}
                    style={{ maxHeight: styles.height }}
                    src={imgSrc}
                  />
                  <img className="after" src={klickicon} alt="" />
                </span>
              )
            })
          ) : null}
        </div>

        <div className="columns is-mobile">
          <div className="column">
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              id="fileinput"
              capture="environment"
              multiple={Boolean(step.multiple)}
              onChange={e => this.fileSelected(e, step)}
            />
            <label
              htmlFor="fileinput"
              className="button is-primary is-fullwidth"
            >
              Bild wählen
            </label>
          </div>
          <div className="column">
            <Link
              to={
                nextStep
                  ? '/reports/submit/step/' + nextStep.id
                  : '/reports/submit'
              }
              className="button is-fullwidth"
            >
              Weiter
            </Link>
          </div>
        </div>

        <Modal
          isOpen={typeof currentStepOpenImageModelData !== 'undefined'}
          style={customStylesModal}
          onRequestClose={this.closeModal.bind(this)}
        >
          {currentStepOpenImageModelData ? (
            <div>
              <div className="columns is-mobile">
                <div className="column">
                  <button
                    type="button"
                    className="button is-fullwidth is-danger"
                    disabled={step.special === 'insurance'}
                    onClick={() => {
                      let newSteps = Object.assign({}, this.props.report.steps)
                      newSteps[step.id] = [...newSteps[step.id]]
                      newSteps[step.id].splice(this.state.modalIsOpen, 1)
                      this.closeModal()
                      this.props.dispatch({
                        type: 'SET_REPORT',
                        report: Object.assign({}, this.props.report, {
                          steps: newSteps,
                          isSyncWithServer: false
                        })
                      })
                    }}
                  >
                    Bild löschen
                  </button>
                </div>
                <div className="column"></div>
                <div className="column">
                  <button
                    onClick={this.closeModal.bind(this)}
                    type="button"
                    className="button is-fullwidth"
                  >
                    Fertig
                  </button>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea is-info"
                    placeholder="Kommentar"
                    onChange={e => {
                      let newSteps = this.props.report.steps
                      newSteps[step.id][this.state.modalIsOpen].comment =
                        e.target.value
                      this.props.dispatch({
                        type: 'SET_REPORT',
                        report: Object.assign({}, this.props.report, {
                          steps: newSteps,
                          isSyncWithServer: false
                        })
                      })
                    }}
                    autoFocus={
                      !currentStepOpenImageModelData.comment && step.special
                    }
                    value={
                      currentStepOpenImageModelData.comment
                        ? currentStepOpenImageModelData.comment
                        : step.special
                        ? specialDefaultComments[step.id]
                        : ''
                    }
                  />
                </div>
              </div>
              <img
                alt={currentStepOpenImageModalImgKey}
                key={currentStepOpenImageModalImgKey}
                src={currentStepOpenImageModalImgSrc}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    )
  }

  specialStep(step) {
    if (step.special === 'insurance') {
      let currentStepData
      if (step.id in this.props.report.steps) {
        currentStepData = this.props.report.steps[step.id][0]
      }

      return (
        <div className="field">
          <div className="control">
            <textarea
              className="textarea is-info"
              placeholder="Versicherungsdaten"
              onChange={e => {
                let newSteps = this.props.report.steps
                if (!currentStepData) {
                  newSteps[step.id] = [{ imageId: blackGuid, noop: true }]
                }
                newSteps[step.id][0].comment = e.target.value
                this.props.dispatch({
                  type: 'SET_REPORT',
                  report: Object.assign({}, this.props.report, {
                    steps: newSteps,
                    isSyncWithServer: false
                  })
                })
              }}
              value={
                currentStepData && currentStepData.comment
                  ? currentStepData.comment
                  : specialDefaultComments[step.id]
              }
            />
          </div>
        </div>
      )
    }
    return null
  }
}

const ReportStepRedux = connect(mapStateToProps)(ReportStep)

const InsuranceTypes = ['Vollkasko', 'Teilkasko', 'Haftpflicht']

const reportH1 = report => {
  if (!report.employee || report.employee.name === 'Gast') {
    return report.title
  }
  return `${report.title} - ${report.employee.name}`
}

const kennzeichenNormalize = input => {
  input = input.toUpperCase()
  input = input.replace(/-/g, ' ')
  input = input.replace(/[^A-Z0-9 ÄÖÜ]/g, '')

  let wasChar = false
  for (let i = 0; i < input.length; i++) {
    if (/[A-Z]/.test(input[i])) {
      wasChar = true
      continue
    }
    if (wasChar) {
      if (/[0-9]/.test(input[i])) {
        input = input.substring(0, i) + ' ' + input.substring(i)
        break
      }
      wasChar = false
    }
  }

  let parts = input
    .split(' ')
    .map(str => str.trim())
    .filter(str => str.length)

  let endsWithSpace = input.charAt(input.length - 1) === ' '

  return parts.join(' ') + (endsWithSpace ? ' ' : '')
}

class ReportSubmit extends Component {
  constructor(props) {
    super(props)
    this.state = { submitting: false, progress: {} }
  }

  render() {
    const { report, stepsConfig } = this.props

    if (!report) {
      return <Redirect to="/reports" />
    }

    if (!stepsConfig) {
      return <LoadBlock height="300px" />
    }

    return (
      <div>
        <Route path="/reports/submit/step/:step" component={ReportStepRedux} />
        <Route exact path="/reports/submit">
          <h1>{reportH1(report)}</h1>

          <form
            className="list is-hoverable"
            onSubmit={e => e.preventDefault()}
            ref="form"
          >
            <div className="list-item">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Kennzeichen</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <div className="container">
                        <input
                          type="text"
                          name="licenseplate"
                          className="licenseplate"
                          style={{ backgroundImage: `url(${euplate})` }}
                          required
                          value={
                            report.title === 'Neuer Auftrag' ? '' : report.title
                          }
                          maxLength="12"
                          onChange={e => {
                            this.props.dispatch({
                              type: 'SET_REPORT',
                              report: Object.assign({}, report, {
                                title: kennzeichenNormalize(e.target.value),
                                isSyncWithServer: false
                              })
                            })
                          }}
                          placeholder="ND AB 123"
                        />
                      </div>
                      {report.title &&
                      report.title !== 'Neuer Auftrag' &&
                      (report.title.match(/ /g) || []).length < 2 ? (
                        <div
                          style={{
                            marginTop: '10px',
                            color: '#ff9970',
                            fontWeight: 'bold',
                            fontSize: '12px'
                          }}
                        >
                          Bitte achten Sie auf korrekte Leerzeichentrennung zw.
                          Stadt, Buchstaben und Zahlen. Beispiel: 'ND AB 123'
                        </div>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {report.employee && report.employee.name !== 'Gast' && (
              <div className="list-item">
                Serviceberater: {report.employee.name}
              </div>
            )}
            <div className="list-item">
              Versicherung:
              <div className="control">
                {InsuranceTypes.map(insurance => (
                  <label className="radio" key={insurance}>
                    <input
                      type="radio"
                      name="insurance"
                      required
                      checked={report.insurance === insurance}
                      onChange={e => {
                        this.props.dispatch({
                          type: 'SET_REPORT',
                          report: Object.assign({}, report, {
                            insurance: e.target.value,
                            isSyncWithServer: false
                          })
                        })
                      }}
                      value={insurance}
                    />
                    {insurance}
                  </label>
                ))}
              </div>
            </div>
            {stepsConfig
              .filter(step => !step.deleted)
              .map(step => (
                <Link
                  key={step.id}
                  to={'/reports/submit/step/' + step.id}
                  className="list-item"
                >
                  (img) {step.name}{' '}
                  <output>
                    ({report.steps[step.id] ? report.steps[step.id].length : 0})
                  </output>
                </Link>
              ))}
            {isGuestMode && (
              <div className="list-item">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Ihre Kontaktdaten</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <input
                          value={report.contact_info}
                          className="input is-primary"
                          type="text"
                          placeholder="Ihre Telefonnummer oder E-Mail Adresse"
                          required
                          onChange={e => {
                            this.props.dispatch({
                              type: 'SET_REPORT',
                              report: Object.assign({}, report, {
                                contact_info: e.target.value,
                                isSyncWithServer: false
                              })
                            })
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="list-item">
              <label>
                <input
                  type="checkbox"
                  checked={report.repairDone}
                  onChange={e => {
                    this.props.dispatch({
                      type: 'SET_REPORT',
                      report: Object.assign({}, report, {
                        repairDone: e.target.checked
                      })
                    })
                  }}
                />{' '}
                Reparatur Fertiggestellt
              </label>
            </div>
          </form>

          <button
            type="button"
            className="button is-fullwidth is-primary"
            disabled={!this.props.connection || this.state.submitting}
            onClick={() => {
              if (!this.refs.form.reportValidity()) {
                return
              }
              let requiredStepFail
              stepsConfig
                .filter(step => step.required)
                .some(requiredStep => {
                  let numStepImgs = report.steps[requiredStep.id]
                    ? report.steps[requiredStep.id].length
                    : 0
                  if (!numStepImgs) {
                    requiredStepFail = requiredStep
                    return true
                  }
                  return false
                })
              if (requiredStepFail) {
                toast.warning({
                  title: requiredStepFail.name,
                  message: 'Zu diesem Schritt muss ein Bild hinterlegt werden',
                  timeout: false
                })
                return
              }
              this.executeSubmit()
            }}
          >
            {this.state.submitting
              ? `Senden ... (${this.state.progress.finishedImages}/${this.state.progress.totalImages} Bilder)`
              : 'Auftrag absenden'}
          </button>
        </Route>
      </div>
    )
  }

  executeSubmit() {
    this.setState({
      submitting: true,
      progress: { totalImages: 0, finishedImages: 0, failedImages: 0 }
    })

    const idPresubmit = this.props.report.guid

    try {
      submit(this.submitProgress.bind(this))
        .then(res => {
          if (this.state.progress.failedImages) {
            toast.warning({
              title: 'Upload Warnung',
              message: `${this.state.progress.failedImages} Bilder wurden nicht übermittelt. Bitte erneut versuchen.`,
              timeout: false
            })
          } else {
            toast.success({
              title:
                'Upload erfolgreich. Vielen Dank, wir nehmen bald mit Ihnen Kontakt auf.',
              timeout: false
            })
            this.props.dispatch({
              type: 'SUBMIT_OK',
              report: this.props.report
            })
            this.props.dispatch({
              type: 'SUBMIT_OK',
              report: { guid: idPresubmit }
            })
            if (!isGuestMode) {
              callApiChecked('assessment').then(reportsData =>
                this.props.dispatch({
                  type: 'RECEIVE_SERVER_REPORTS',
                  data: reportsData,
                  user: this.props.user
                })
              )
            } else {
              this.props.dispatch({ type: 'CLOSE_REPORT' })
            }
            if (isGuestMode) {
              this.props.history.push('/')
            }
          }
          this.setState({ submitting: false })
        })
        .catch(err => {
          toast.error({
            title: 'Fehler beim Senden',
            message: err.message
          })
          this.setState({ submitting: false })
        })
    } catch (err) {
      toast.error({
        title: 'Fehler beim Senden',
        message: err.message
      })
      this.setState({ submitting: false })
    }
  }

  submitProgress(msg) {
    console.log(msg)
    if (msg === 'queueImage') {
      this.setState({
        progress: Object.assign({}, this.state.progress, {
          totalImages: this.state.progress.totalImages + 1
        })
      })
    }
    if (msg === 'finishImage') {
      this.setState({
        progress: Object.assign({}, this.state.progress, {
          finishedImages: this.state.progress.finishedImages + 1
        })
      })
    }
    if (msg === 'imgErr') {
      this.setState({
        progress: Object.assign({}, this.state.progress, {
          failedImages: this.state.progress.failedImages + 1
        })
      })
    }
  }
}

export default connect(mapStateToProps)(withRouter(ReportSubmit))
