import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { template } from '../reducers/currentReport'
import { cleanup as cleanupImageStorage } from '../service/imageStorage'
import classNames from 'classnames'
import './ReportsIndex.scss'

import {
  FaTrash,
  FaCheckCircle,
  FaEnvelopeSquare,
  FaPenSquare
} from 'react-icons/fa'

const uuidv1 = require('uuid/v1')

class ReportsIndex extends Component {
  constructor(props) {
    super(props)
    this.state = { search: '' }
  }

  render() {
    return (
      <div>
        <button
          className="button is-fullwidth is-primary"
          type="button"
          onClick={() => {
            if (this.props.currentReport) {
              this.props.dispatch({
                type: 'SAVE_DRAFT',
                report: this.props.currentReport
              })
            }
            this.props.dispatch({
              type: 'SET_REPORT',
              report: Object.assign(
                { guid: uuidv1(), employee: this.props.currentEmployee },
                template
              )
            })
            this.props.history.push('/reports/submit')
            cleanupImageStorage()
          }}
        >
          Neuen Auftrag erfassen
        </button>
        <hr />
        {this.props.currentReport ? (
          <Link to="/reports/submit" className="button is-fullwidth is-info">
            Weiter mit Erfassung von {this.props.currentReport.title}
          </Link>
        ) : null}
        <hr />
        <h4>Alle Aufträge</h4>
        <div className="field">
          <div className="control">
            <input
              value={this.state.search}
              className="input is-info"
              type="search"
              placeholder="Suche"
              required
              onChange={e => {
                this.setState({ search: e.target.value })
              }}
            />
          </div>
        </div>
        {this.renderReportList()}
      </div>
    )
  }

  renderReportList() {
    let usedIds = []

    return (
      <div className="list is-hoverable">
        {Object.values(this.props.drafts)
          .concat(Object.values(this.props.serverReports))
          .map(draft => {
            if (usedIds.indexOf(draft.guid) !== -1) {
              return null
            }
            usedIds.push(draft.guid)
            if (this.state.search) {
              if (
                draft.title
                  .toLowerCase()
                  .replace(/-/g, ' ')
                  .indexOf(
                    this.state.search.toLowerCase().replace(/-/g, ' ')
                  ) === -1
              ) {
                return null
              }
            }
            const isActive =
              this.props.currentReport &&
              this.props.currentReport.guid === draft.guid
            let withDelete = draft.guid in this.props.drafts
            let statusSymbols = []
            if (withDelete) {
              statusSymbols.push(<FaPenSquare key="draft" />)
            }
            if (draft.status === 1) {
              statusSymbols.push(<FaEnvelopeSquare key="sent" />)
            }
            if (draft.status === 2) {
              statusSymbols.push(<FaCheckCircle key="done" />)
            }
            return (
              <div
                key={draft.guid}
                className={classNames({
                  'list-item': true,
                  'is-active': isActive
                })}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (isActive) {
                    return
                  }
                  if (this.props.currentReport) {
                    this.props.dispatch({
                      type: 'SAVE_DRAFT',
                      report: this.props.currentReport
                    })
                  }
                  this.props.dispatch({ type: 'SET_REPORT', report: draft })
                  cleanupImageStorage()
                }}
              >
                {withDelete ? (
                  <span
                    aria-label="Löschen"
                    role="img"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                    onClick={e => {
                      e.stopPropagation()
                      this.props.dispatch({
                        type: 'DELETE_DRAFT',
                        report: draft
                      })
                      cleanupImageStorage()
                    }}
                  >
                    <FaTrash />
                  </span>
                ) : null}
                {draft.title} -{' '}
                {draft.employee
                  ? draft.employee.name
                  : 'Gelöschter Mitarbeiter'}
                {draft.daysOld >= 30 ? (
                  <span
                    style={{
                      marginRight: '5px',
                      marginLeft: '5px',
                      fontSize: '10px',
                      fontWeight: 'bold'
                    }}
                  >
                    Bitte nur noch Nachträge ergänzen. Neuen Auftrag anlegen für
                    neuen Fall.
                  </span>
                ) : null}
                <span
                  style={{
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {draft.date ? (
                    <small style={{ fontSize: '13px', marginRight: '4px' }}>
                      gesendet {new Date(draft.date).toLocaleDateString()}
                    </small>
                  ) : (
                    ''
                  )}
                  {statusSymbols}
                </span>
                <div style={{ clear: 'both' }}></div>
              </div>
            )
          })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentReport: state.currentReport,
    currentEmployee: state.user.currentEmployee,
    drafts: state.reports.drafts,
    serverReports: state.reports.server
  }
}

export default connect(mapStateToProps)(ReportsIndex)
